import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/LoginView.vue";
import NotFoundView from "@/views/NotFoundView";
import {Permissions} from "@/js/Permissions";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/",
    name: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/DashboardView.vue"),
    meta: {permissions: [Permissions.DISPUTES, Permissions.RESEARCH, Permissions.ORION]},
  },
  {
    path: "/disputes",
    name: "disputes_index",
    component: () => import("../views/disputes/IndexView.vue"),
    meta: {permissions: [Permissions.DISPUTES]},
  },
  {
    path: "/disputes/create",
    name: "disputes_create",
    component: () => import("../views/disputes/CreateView.vue"),
    meta: {permissions: [Permissions.DISPUTES]},
  },
  {
    path: "/disputes/:id",
    name: "disputes_edit",
    component: () => import("../views/disputes/EditView.vue"),
    meta: {permissions: [Permissions.DISPUTES, Permissions.PREVIEW_DISPUTE]},
  },
  {
    path: '/libra',
    name: 'libra_index',
    component: () => import("@/views/settings/IndexView.vue"),
    meta: {permissions: [Permissions.LIBRA]},
  },
  {
    path: '/settings',
    name: 'settings_index',
    component: () => import("@/views/settings/IndexView.vue"),
    meta: {permissions: [Permissions.LIBRA]},
  },
  {
    path: "/airs/criminal",
    name: "criminal_index",
    component: () => import("../views/airs/criminal/IndexView.vue"),
    meta: {permissions: [Permissions.AIRS]},
  },
  {
    path: "/airs/criminal/review",
    name: "criminal_review_index",
    component: () => import("../views/airs/criminal/ReviewIndexView.vue"),
    meta: {permissions: [Permissions.AIRS]},
  },
  {
    path: "/airs/criminal/review/batch-review",
    name: "criminal_batch_review",
    component: () => import("../views/airs/criminal/BatchReviewView.vue"),
    meta: {permissions: [Permissions.AIRS]},
  },
  {
    path: "/airs/criminal/create",
    name: "criminal_create",
    component: () => import("../views/airs/criminal/CreateView.vue"),
    meta: {permissions: [Permissions.AIRS]},
  },
  {
    path: "/airs/criminal/import-history",
    name: "criminal_import_history",
    component: () => import("../views/airs/criminal/ImportHistoryView.vue"),
    meta: {permissions: [Permissions.AIRS]},
  },
  {
    path: "/airs/criminal/import-history/import",
    name: "criminal_import",
    component: () => import("../views/airs/criminal/ImportView.vue"),
    meta: {permissions: [Permissions.AIRS]},
  },
  {
    path: "/airs/criminal/import-history/import/:id/headers",
    name: "criminal_import_headers",
    component: () => import("../views/airs/criminal/ImportHeadersView.vue"),
    meta: {permissions: [Permissions.AIRS]},
  },
  {
    path: "/airs/criminal/import-history/import/:id/mapping",
    name: "criminal_import_mapping",
    component: () => import("../views/airs/criminal/ImportMappingView.vue"),
    meta: {permissions: [Permissions.AIRS]},
  },
  {
    path: "/airs/criminal/import-history/import/:id/preview",
    name: "criminal_import_preview",
    component: () => import("../views/airs/criminal/ImportPreviewView.vue"),
    meta: {permissions: [Permissions.AIRS]},
  },
  {
    path: "/airs/criminal/:id",
    name: "criminal_edit",
    component: () => import("../views/airs/criminal/EditView.vue"),
    meta: {permissions: [Permissions.AIRS]},
  },
  {
    path: "/users",
    name: "users_index",
    component: () => import("../views/admin/users/IndexView.vue"),
    meta: {permissions: [Permissions.ADMIN]},
  },
  {
    path: "/users/create",
    name: "users_create",
    component: () => import("../views/admin/users/CreateView.vue"),
    meta: {permissions: [Permissions.ADMIN]},
  },
  {
    path: "/users/:id",
    name: "users_edit",
    component: () => import("../views/admin/users/EditView.vue"),
    meta: {permissions: [Permissions.ADMIN]},
  },
  {
    path: "/admin/expedite-settings",
    name: "expedite_settings_index",
    component: () => import("../views/admin/expediteSettings/IndexView.vue"),
    meta: {permissions: [Permissions.ADMIN, Permissions.EXPEDITE_SETTINGS]},
  },
  {
    path: "/research",
    name: "research_index",
    component: () => import("../views/research/IndexView.vue"),
    meta: {permissions: [Permissions.RESEARCH]},
  },
  {
    path: "/research/create",
    name: "research_create",
    component: () => import("../views/research/CreateView.vue"),
    meta: {permissions: [Permissions.RESEARCH]},
  },
  {
    path: "/research/:id",
    name: "research_edit",
    component: () => import("../views/research/EditView.vue"),
    meta: {permissions: [Permissions.RESEARCH, Permissions.PREVIEW_RESEARCH]},
  },
  {
    path: "/orion",
    name: "orion_index",
    component: () => import("../views/orion/uds/IndexView.vue"),
    meta: {permissions: [Permissions.ORION_EDIT_RESEARCHER_ACCOUNT_SETTINGS]},
  },
  {
    path: "/orion/records/:id",
    name: "orion_edit",
    component: () => import("../views/orion/uds/EditView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/deletes-log",
    name: "orion_deletes_history_index",
    component: () => import("../views/orion/DeleteHistoryIndex.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/edit-queue",
    name: "orion_editqueue_index",
    component: () => import("../views/orion/editqueue/IndexView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/edit-queue/researcher-summary",
    name: "orion_editqueue_researcher_summary",
    component: () => import("../views/orion/editqueue/ResearcherSummaryView.vue"),
    meta: {permissions: [Permissions.ORION_EDIT_QUEUE_REVIEW]},
  },
  {
    path: "/orion/edit-queue/error-summary",
    name: "orion_editqueue_error_summary",
    component: () => import("../views/orion/editqueue/errorSummary/IndexView.vue"),
    meta: {permissions: [Permissions.ORION_EDIT_QUEUE_REVIEW]},
  },
  {
    path: "/orion/edit-queue/:id",
    name: "orion_editqueue_edit",
    component: () => import("../views/orion/editqueue/SingleEditView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/edit-queue/duplicates/:id",
    name: "orion_editqueue_duplicates",
    component: () => import("../views/orion/editqueue/DuplicatesView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/clients",
    name: "orion_clients_index",
    component: () => import("../views/orion/clients/IndexView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/clients/create",
    name: "orion_create_client_account",
    component: () => import("../views/orion/clients/CreateView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/clients/:id",
    name: "orion_clients_edit",
    component: () => import("../views/orion/clients/EditView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/researchers",
    name: "orion_researchers_index",
    component: () => import("../views/orion/researchers/IndexView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/researchers/create",
    name: "orion_create_researcher_account",
    component: () => import("../views/orion/researchers/CreateView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/researchers/documents",
    name: "orion_researchers_documents",
    component: () => import("../views/orion/researchers/documents/IndexView.vue"),
    meta: {permissions: [Permissions.ORION, Permissions.ORION_ALL_RESEARCHER_ATTACHMENTS]},
  },
  {
    path: "/orion/researchers/:id",
    name: "orion_researchers_edit",
    component: () => import("../views/orion/researchers/EditView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/researchers/additional-payments/:id",
    name: "orion_researchers_additional_payments",
    component: () => import("../views/orion/researchers/additional_payments/IndexView.vue"),
    meta: {permissions: [Permissions.ORION_CREATE_ADDITIONAL_PAYMENTS]},
  },
  {
    path: "/orion/transmittals-queue",
    name: "orion_transmittals_queue",
    component: () => import('../views/orion/transmittals/TransmittalsQueueView'),
    meta: {permissions: [Permissions.ORION_EDIT_RESEARCHER_ACCOUNT_SETTINGS]},
  },
  {
    path: "/orion/transmittals-history",
    name: "orion_transmittals_history",
    component: () => import('../views/orion/transmittals/TransmittalsHistoryView.vue'),
    meta: {permissions: [Permissions.ORION_EDIT_RESEARCHER_ACCOUNT_SETTINGS]},
  },
  {
    path: "/orion/housing-court-uploads",
    name: "orion_hcr_uploads_index",
    component: () => import("../views/orion/uploads/IndexView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/nj-uploads",
    name: "orion_nj_uploads_index",
    component: () => import("../views/orion/njUploads/IndexView.vue"),
    meta: {permissions: [Permissions.ORION_HCR_UPLOAD_NJ_DISPOSITION]},
  },
  {
    path: "/orion/courts",
    name: "orion_courts_index",
    component: () => import("../views/orion/courts/IndexView.vue"),
    meta: {permissions: [Permissions.ORION]},// TODO: permissions
  },
  {
    path: "/orion/courts/create",
    name: "orion_courts_create",
    component: () => import("../views/orion/courts/EditView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "/orion/courts/:id",
    name: "orion_courts_edit",
    component: () => import("../views/orion/courts/EditView.vue"),
    meta: {permissions: [Permissions.ORION]},
  },
  {
    path: "*",
    name: "404",
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// Check that the isLoggedIn localstorage value is set to see if they are logged in.
// If isLoggedIn is true, but the cookie has been deleted or expired, there is an axios
// interceptor in main.js that will redirect users to the login if an API call results
// in a 401
router.beforeEach(async (to, from, next) => {
  // These routes do not need to be protected by authentication
  // routes to be ignored / don't do anything if going here
  if (['login'].indexOf(to.name) !== -1) {
    next();
    return;
  }

  if (!isLoggedIn()) {
    // next({name: 'login'});
    await router.push({name: 'login'});
    return;
  }

  const permissions = localStorage.getItem('permissions').split(',') || [];

  if (permissions.some(permission => to.meta.permissions.includes(permission))) {
    next();
  } else {
    await router.push({name: 'dashboard'});
  }
});

function isLoggedIn() {
  return localStorage.getItem('isLoggedIn');
}

export default router;
